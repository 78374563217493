import React, { useState, useEffect } from "react";
import Link from "next/link";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Hidden,
  Typography,
  Divider,
} from "@mui/material";

// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    // elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(5),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiList-root": {
      padding: "4px 0",
    },
    "& .MuiListItem-root": {
      ...theme.typography.body1,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const AdminButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
      >
        Admin
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Link href="/admin/lessons" passHref>
          <MenuItem component="a" disableRipple>
            Lessons
          </MenuItem>
        </Link>
        {/* <Link href="/admin/rentals" passHref>
          <MenuItem component="a" disableRipple>
            Rentals
          </MenuItem>
        </Link>
        <Link href="/admin/inventory" passHref>
          <MenuItem component="a" disableRipple>
            Inventory
          </MenuItem>
        </Link> */}
        <Link href="/admin/media" passHref>
          <MenuItem component="a" disableRipple>
            Media
          </MenuItem>
        </Link>
        {/* <MenuItem onClick={handleClose} disableRipple>
          Key/Values
        </MenuItem> */}
        <Link href="/admin/partners" passHref>
          <MenuItem component="a" disableRipple>
            Partners
          </MenuItem>
        </Link>
        <Link href="/admin/promocodes" passHref>
          <MenuItem component="a" disableRipple>
            Promocodes
          </MenuItem>
        </Link>
        <Divider />

        <Link
          href="/api/logout"
          onClick={async (e) => {
            e.preventDefault();
            mutateUser(
              await fetchJson("/api/auth/logout", { method: "POST" }),
              false
            );
            router.push("/");
          }}
        >
          <MenuItem component="a" disableRipple>
            Logout
          </MenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
};

export default AdminButton;
