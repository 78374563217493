import {
  Container,
  Grid,
  IconButton,
  Link,
  Hidden,
  Button,
  Typography,
} from "@mui/material";

const Footer = (props) => {
  return (
    <Grid item style={{ background: "#fff", borderTop: "1px solid #ddd" }}>
      <Container style={{ maxWidth: 1280 }}>
        <Grid
          container
          style={{
            minHeight: 180,
            width: "100%",
            paddingTop: 24,
            marginTop: 0,
          }}
          spacing={3}
          wrap="nowrap"
        >
          <Grid item md={3}>
            {/* <Typography variant="h6">Big Surf Adventures</Typography>
            <br /> */}

            <p>
              <span className="icon">
                <i className="fab fa-facebook"></i>
              </span>
              <a
                href="https://www.facebook.com/Bigsurflessonsandadventures"
                className="has-text-white"
                target="_blank"
              >
                Facebook
              </a>
            </p>
            <p>
              <span className="icon">
                <i className="fab fa-instagram"></i>
              </span>
              <a
                href="https://instagram.com/bigsurfadventures"
                className="has-text-white"
                target="_blank"
              >
                Instagram
              </a>
            </p>
            <p>
              <span className="icon">
                <i className="fab fa-tripadvisor"></i>
              </span>
              <a
                href="https://www.tripadvisor.com/Attraction_Review-g32737-d18688953-Reviews-Big_Surf_Lessons_Adventures-Monterey_Monterey_County_California.html"
                className="has-text-white"
                target="_blank"
              >
                TripAdvisor
              </a>
            </p>
            <p>
              <span className="icon">
                <i className="fab fa-yelp"></i>
              </span>
              <a
                href="https://www.yelp.com/biz/big-surf-adventures-pacific-grove"
                className="has-text-white"
                target="_blank"
              >
                Yelp
              </a>
            </p>
            <p>
              <span className="icon">
                <i className="fas fa-map"></i>
              </span>
              <a
                href="https://www.google.com/maps/place/Big+Surf+Lessons+%26+Adventure+Tours/@36.3881864,-121.8181792,10z/data=!4m2!3m1!1s0x0:0xd4d284b33e9421f0?sa=X&ved=2ahUKEwiKvae4wqHkAhXRpJ4KHVkVAlgQ_BIwCnoECA8QCA"
                className="has-text-white"
                target="_blank"
              >
                Google Maps
              </a>
            </p>
          </Grid>
          <Grid item md={3}>
            {/* <Grid container direction="column">
              <Grid item>
                <Typography variant="h6">Privacy</Typography>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Terms of Service
                </Link>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item md={3}>
            {/* <Grid container direction="column">
              <Grid item>
                <Typography variant="h6">Product</Typography>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Pricing
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Feature Overview
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Testimonials
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Visit App / Login
                </Link>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid item md={3}>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h6">Support</Typography>
              </Grid>
              {/* <Grid item>
                <Link to="/" variant="subtitle1">
                  Help Center
                </Link>
              </Grid>
              <Grid item>
                <Link to="/" variant="subtitle1">
                  Contact Us
                </Link>
              </Grid> */}
              <Grid item>
                <Link
                  to="mailto:hello@bigsurfadventures.com"
                  variant="subtitle1"
                >
                  hello@bigsurfadventures.com
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default Footer;
