import { Container, Grid, Button, Box, Typography } from "@mui/material";

const Hero = ({ bgImg, bg, bgPosition = "top", minHeight, children }) => {
  // TODO: allow just the `folder/uuid` to be provided? or the media id?
  return (
    <Box
      sx={{
        minHeight: minHeight || 400,
        background: bgImg
          ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${bgImg}) no-repeat`
          : bg,
        backgroundSize: "cover",
        backgroundPosition: bgPosition,
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{
          minHeight: minHeight || 400,
        }}
      >
        {children}
      </Grid>
    </Box>
  );
};

export default Hero;
