import ImgixDefault from "react-imgix";
import { Box } from "@mui/material";

const Imgix = (props) => {
  // TODO: allow just the `folder/uuid` to be provided? or the media id?
  const { boxProps, ...restProps } = props;
  return (
    <Box sx={{ "> img": { width: "100%" } }} {...boxProps}>
      <ImgixDefault {...restProps} />
    </Box>
  );
};

export default Imgix;
