import { useState, useEffect } from "react";
import { Grid } from "@mui/material";

import { Header } from "../Header";
import { Footer } from "../Footer";

const Layout = ({ children }) => {
  return (
    <Grid container direction="column" style={{ minHeight: "100vh" }}>
      <Header />
      {children}
      <Footer />
    </Grid>
  );
};

export default Layout;
