import React, { useState, useEffect } from "react";
import Link from "next/link";
import { styled, alpha } from "@mui/material/styles";
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  Hidden,
  Typography,
  Divider,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const StyledMenu = styled((props) => (
  <Menu
    // elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(5),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiList-root": {
      padding: "4px 0",
    },
    "& .MuiListItem-root": {
      ...theme.typography.body1,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const MobileMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClick} endIcon={<MenuIcon />}>
        Menu
      </Button>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Link href="/surf" passHref>
          <MenuItem component="a" disableRipple>
            Surf Lessons
          </MenuItem>
        </Link>
        <Link href="/instructors" passHref>
          <MenuItem component="a" disableRipple>
            Instructors
          </MenuItem>
        </Link>
        <Link href="/locations" passHref>
          <MenuItem component="a" disableRipple>
            Locations
          </MenuItem>
        </Link>
        <Link href="/bike-adventures" passHref>
          <MenuItem component="a" disableRipple>
            Biking
          </MenuItem>
        </Link>
        <Link href="/gear" passHref>
          <MenuItem component="a" disableRipple>
            Rentals / Gear
          </MenuItem>
        </Link>
        <Divider />
        <Link
          // href="/book/create"
          href="https://fareharbor.com/embeds/book/bigsurfadventures/?full-items=yes&flow=1207493"
          passHref
        >
          <MenuItem component="a" disableRipple>
            <Button variant="contained" size="small" fullWidth>
              Reserve Now!
            </Button>
          </MenuItem>
        </Link>
        <Divider />
        <Link href="tel+18312648008" passHref>
          <MenuItem component="a" disableRipple>
            Call Us: (831) 264-8008
          </MenuItem>
        </Link>
      </StyledMenu>
    </div>
  );
};

export default MobileMenu;
